export const HOME_FETCH_POOLS_BEGIN = 'HOME_FETCH_POOLS_BEGIN';
export const HOME_FETCH_POOLS_DONE = 'HOME_FETCH_POOLS_DONE';
export const WALLET_ACTION = 'WALLET_ACTION';
export const WALLET_ACTION_RESET = 'WALLET_ACTION_RESET';
export const BALANCE_FETCH_BALANCES_BEGIN = 'BALANCE_FETCH_BALANCES_BEGIN';
export const BALANCE_FETCH_BALANCES_DONE = 'BALANCE_FETCH_BALANCES_DONE';
export const BALANCE_RESET = 'BALANCE_RESET';
export const EARNED_FETCH_EARNED_BEGIN = 'EARNED_FETCH_EARNED_BEGIN';
export const EARNED_FETCH_EARNED_DONE = 'EARNED_FETCH_EARNED_DONE';
export const EARNED_RESET = 'EARNED_RESET';
export const ZAP_SWAP_ESTIMATE_PENDING = 'ZAP_SWAP_ESTIMATE_PENDING';
export const ZAP_SWAP_ESTIMATE_COMPLETE = 'ZAP_SWAP_ESTIMATE_COMPLETE';
export const FETCH_BUYBACKS_BEGIN = 'FETCH_BUYBACKS_BEGIN';
export const FETCH_BUYBACKS_SUCCESS = 'FETCH_BUYBACKS_SUCCESS';
export const FETCH_BUYBACKS_FAILURE = 'FETCH_BUYBACKS_FAILURE';
export const FETCH_HOLDERS_BEGIN = 'FETCH_HOLDERS_BEGIN';
export const FETCH_HOLDERS_SUCCESS = 'FETCH_HOLDERS_SUCCESS';
export const FETCH_HOLDERS_FAILURE = 'FETCH_HOLDERS_FAILURE';
